import axios from "../utils/axios";

export const recordSignIn: (
    userInformation: any
) => Promise<any> = async (
    userInformation: any
): Promise<any> => {
    const response = await axios.post(`/api/user-activity/signin`,
        userInformation);

    return response?.data || null
}

export const getUserActivity: (
    perPage: number,
    lastItemTimeStamp: string,
) => Promise<any> = async (
    perPage: number,
    lastItemTimeStamp: string,
): Promise<any> => {
    const response = await axios.get(`/api/admin/dashboard/user-activity`, {
        params: {
            perPage: perPage,
            lastItemTimeStamp: lastItemTimeStamp,
        }
    });

    return response?.data?.data || null
}